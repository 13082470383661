/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    a: "a",
    div: "div",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Trackerdetect is a first and foremost a monitoring service that will analyze your sites\nautomatically for new trackers and security issues."), "\n", React.createElement(_components.h2, {
    id: "e-mail",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#e-mail",
    "aria-label": "e mail permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "E-mail"), "\n", React.createElement(_components.p, null, "You can choose to receive alerts from Trackerdetect in e-mail. Go to your ", React.createElement(_components.a, {
    href: "/app/account#email"
  }, "account"), ",\nand make sure the ", React.createElement(_components.code, null, "trackerdetect"), " event is added to the e-mail topics."), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 764px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 47.5%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAAsTAAALEwEAmpwYAAABIElEQVQoz5XQ3W7DIAwF4Lz/I1Zq0gxGgQLChL9gCFMSdap6sWrflX1xJB8PvXd9UEoZY0KMM6GUsZnQmdAQopQSALz3WuuUkjo451prw+VyAYDee2utIkZEuQQdk45JhZiwlnVFxFprKaW1VkrBw7ZtwziOWmsAMMYgYv+PwRijlNJaCyFijCnnnNecc9iXXTykFznnGGOtdZimiVLqnLOHs7+1FgCcc8uyeO8B4ByWJ2ttKWWQUgohjDHnYK1ljDnneu/btn04+9s6JYR8PJRSQgjGGKX0fOHn8DR/TdN0u93u9/teNexlc86IWF6sx8/fw5xzSinn3BgjhJBScs5/i8CTtTaE8B6e53kcR0JIKWU8XK9XQsi6rt77v8/+AYhLPT9CmCueAAAAAElFTkSuQmCC'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"E-mail topics\"\n        title=\"E-mail topics\"\n        src=\"/static/cd1ccf088ee13b4c31eed94bd04da4bd/030ec/email-topics.png\"\n        srcset=\"/static/cd1ccf088ee13b4c31eed94bd04da4bd/f8f3a/email-topics.png 200w,\n/static/cd1ccf088ee13b4c31eed94bd04da4bd/6a8a8/email-topics.png 400w,\n/static/cd1ccf088ee13b4c31eed94bd04da4bd/030ec/email-topics.png 764w\"\n        sizes=\"(max-width: 764px) 100vw, 764px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.h2, {
    id: "channels",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#channels",
    "aria-label": "channels permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Channels"), "\n", React.createElement(_components.p, null, "You can also receive events using the flexible ", React.createElement(_components.a, {
    href: "/docs/api/v1/integration/channels"
  }, "Channels"), " system."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
